





import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import { GroupConfigKey } from '@technology/collaborate-next-js'
import { TaskArticleModel, TaskModel } from '@technology/collaborate-next-js/dist/types/models'
@Component({
  name: 'dashboard-collaborate-topic-management-widget-content-data-handler',
})
export default class DataHandler extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]

  get slotProps() {
    return {
      listItems: this.listItems,
      getArticlesByTask: this.getArticlesByTask,
      getTaskArticleStatusesByArticle: this.getTaskArticleStatusesByArticle,
      getTaskArticlePublishStatusesByArticle: this.getTaskArticlePublishStatusesByArticle,
      getCategoryByTask: this.getCategoryByTask,
      getNewsPublishByTask: this.getNewsPublishByTask,
      getParentTaskByTask: this.getParentTaskByTask,
      getTaskAssetsByTask: this.getTaskAssetsByTask,
      checkFieldCanShow: this.checkFieldCanShow,
    }
  }

  get listItems() {
    return this.$store.myGetters.getTasksByIds(this.dataIds)
  }

  public getArticlesByTask(task: TaskModel) {
    const taskId = task.parentTaskId ?? task.id
    return this.$store.myGetters.getTaskArticlesByTaskId(taskId) ?? []
  }

  public getCategoryByTask(task: TaskModel) {
    return this.$store.myGetters.getCategoryById(task.categoryId) ?? undefined
  }

  public getTaskArticleStatusesByArticle(article: TaskArticleModel) {
    return this.$store.myGetters.getTaskArticleStatusesByArticleId(article.id) ?? []
  }

  public getTaskArticlePublishStatusesByArticle(article: TaskArticleModel) {
    return this.$store.myGetters.getTaskArticlePublishStatusesByArticleId(article.id) ?? []
  }

  public getNewsPublishByTask(task: TaskModel) {
    const taskId = task.parentTaskId ?? task.id
    return this.$store.myGetters.getNewsPublishByTaskId(taskId) ?? undefined
  }

  public getTaskAssetsByTask(task: TaskModel) {
    const taskId = task.parentTaskId ?? task.id
    return this.$store.myGetters.getTaskAssetsByTaskId(taskId) ?? []
  }

  public getParentTaskByTask(task: TaskModel) {
    return this.$store.myGetters.getParentTaskById(task.id) ?? undefined
  }

  get hiddenFields() {
    const formHiddenFields: any = this.$store.myGetters.getGroupConfigByGroupIdAndKey(
      this.config.groupId,
      GroupConfigKey.FormHiddenFields,
    )
    return formHiddenFields ? formHiddenFields : []
  }

  public checkFieldCanShow(key: string) {
    return this.hiddenFields.indexOf(key) === -1
  }
}


































import { Component } from 'vue-property-decorator'
import { Permissions } from '@technology/collaborate-next-js'
import { Validation } from 'vue-plugin-helper-decorator'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'

import DimmedGroupSelect from '~/components/common/widget/selects/DimmedGroupSelect.vue'
import GroupSelect from '~/components/common/widget/selects/GroupSelect.vue'
import WidgetCollapse from '~/types/widgetCollapse'
import eventhub from '~/components/dashboard/widget/collaborate/topicListWidget/eventhub'
import util from '~/util'
@Component({
  components: {
    DimmedGroupSelect,
    GroupSelect,
  },
  name: 'dashboard-collaborate-topic-management-widget-add-widget-dialog',
})
export default class Dialog extends Vue {
  public form: any = {
    groupIds: [],
  }
  public dialog = false
  public $v: any
  public widgetType = 'collab-topic-list-widget'

  // computed
  get allGroups() {
    return this.$store.myGetters['accounts/group/all']
  }

  get filteredGroups() {
    return this.$store.myGetters['accounts/group/allWithoutContainer'].filter(group =>
      this.$store.myGetters['accounts/group/hasPermission'](group.id, Permissions.View),
    )
  }

  get groupIdsError() {
    const errors: string[] = []
    if (this.$v.form.groupIds.$dirty) {
      if (!this.$v.form.groupIds.required) {
        errors.push('groups are required')
      }
    }
    return errors
  }

  get subscribedGroups() {
    const widgetConfigs = this.$store.myGetters.getWidgetConfigsByType(this.widgetType)
    if (widgetConfigs.length) {
      const distinctGroupIds = util.distinct(
        widgetConfigs
          .filter(widgetConfig => widgetConfig.config)
          .map(widgetConfig => widgetConfig.config.groupId),
      )
      return distinctGroupIds.map(distinctGroupId =>
        this.$store.myGetters['accounts/group/getById'](distinctGroupId),
      )
    }
    return []
  }
  // end
  public async handleSubmit() {
    try {
      await this.$store.myActions.createWidgetConfig({
        body: {
          list: this.form.groupIds.map((groupId: string) => ({
            name: 'Topic List by Desk',
            type: this.widgetType,
            config: {
              groupId,
              collapseState: WidgetCollapse.Expand,
            },
          })),
        },
      })
      this.handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  public handleClose() {
    this.dialog = false
  }

  public resetForm() {
    this.$v.$reset()
    this.form = {
      groupIds: [],
    }
  }

  @Validation()
  public validationObject() {
    return {
      form: {
        groupIds: {
          required,
        },
      },
    }
  }

  public created() {
    eventhub.$on('open-add-dialog', () => {
      this.resetForm()
      this.dialog = true
    })
  }

  public beforeDestroy() {
    eventhub.$off('open-add-dialog')
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('topic-list-data-handler',{attrs:{"data-ids":_vm.dataIds,"config":_vm.config},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var checkFieldCanShow = ref.checkFieldCanShow;
var getTaskArticlePublishStatusesByArticle = ref.getTaskArticlePublishStatusesByArticle;
var getArticlesByTask = ref.getArticlesByTask;
var getCategoryByTask = ref.getCategoryByTask;
var getNewsPublishByTask = ref.getNewsPublishByTask;
var getParentTaskByTask = ref.getParentTaskByTask;
var getTaskAssetsByTask = ref.getTaskAssetsByTask;
var listItems = ref.listItems;
return [_c('data-iterator',{attrs:{"items":listItems},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-flex',{directives:[{name:"long-press",rawName:"v-long-press",value:(_vm.handleLongPressClick(props.item, getArticlesByTask(props.item))),expression:"handleLongPressClick(props.item, getArticlesByTask(props.item))"}],attrs:{"xs12":""}},[_c('v-card',{class:_vm.getCorrespondingTaskStyle(props.item),attrs:{"flat":""}},[_c('v-list',{staticClass:"py-2"},[_c('v-list-item',{staticClass:"topic-list-card-listing__column-row-board"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('c-board-display',{attrs:{"task":props.item}})],1)],1)],1),(checkFieldCanShow("TaskModel.title"))?_c('v-card-text',{staticClass:"pt-0"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"topic-list-card-listing__column-row-title"},[_c('c-task-with-article-title-display',{attrs:{"category":getCategoryByTask(props.item),"news-publish":getNewsPublishByTask(props.item),"parent-task":getParentTaskByTask(props.item),"task":props.item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var title = ref.title;
var click = ref.click;
return [_c('p',{staticClass:"c-article-title-display__title mt-3 mb-2",on:{"click":click}},[_vm._v(_vm._s(title))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-card-title',{staticClass:"py-0 topic-list-card-listing__column-row-assignees"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"grow":""}},[_c('c-assignees-display',{attrs:{"task":props.item}})],1)],1)],1),_c('v-list',{staticClass:"py-0"},[(_vm.hasTopicSummary(getParentTaskByTask(props.item)) && checkFieldCanShow("TaskModel.summary"))?_c('v-list-item',{staticClass:"topic-list-card-listing__column-row-topic-summary"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',[_c('c-article-topic-summary-display',{staticClass:"topic-list-card-listing__article-topic-summary",attrs:{"task":props.item,"parent-task":getParentTaskByTask(props.item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var topicSummary = ref.topicSummary;
var click = ref.click;
return [_c('text-clamp',{attrs:{"height":"25px","value":topicSummary}},[_c('span',{on:{"click":click}},[_vm._v(_vm._s(topicSummary))])])]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"topic-list-card-listing__column-row-asset"},[_c('v-list-item-content',[_c('c-assets-display',{attrs:{"task":props.item,"task-assets":getTaskAssetsByTask(props.item)}})],1)],1),(getArticlesByTask(props.item).length)?_vm._l((getArticlesByTask(props.item)),function(article,index){return _c('v-list-item',{key:index,staticClass:"topic-list-card-listing__column-row-article-pub-date"},[_c('v-list-item-content',{staticClass:"topic-list-card-listing__topic-article mr-4"},[_c('c-article-display',{attrs:{"task":props.item,"article":article,"publish-statuses":getTaskArticlePublishStatusesByArticle(article)}})],1),(checkFieldCanShow("TaskModel.issueDate"))?_c('v-list-item-content',{staticClass:"pt-1 topic-list-card-listing__pub-date align-end"},[_c('c-pub-date-display',{attrs:{"task":props.item,"publish-statuses":getTaskArticlePublishStatusesByArticle(article)}})],1):_vm._e()],1)}):_vm._e()],2),_c('v-spacer',{staticClass:"pb-3"}),_c('v-divider')],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }









import { Component } from 'vue-property-decorator'
import { Permissions } from '@technology/collaborate-next-js'
import Vue from 'vue'
import eventhub from './eventhub'

@Component({
  name: 'dashboard-collaborate-topic-list-widget-add-widget-btn',
})
export default class AddWidgetBtn extends Vue {
  get showBtn() {
    return this.$store.myGetters['accounts/group/all'].filter(group =>
      this.$store.myGetters['accounts/group/hasPermission'](group.id, Permissions.View),
    ).length
  }

  public handleClick() {
    eventhub.$emit('open-add-dialog')
  }
}














































































import { Component, Prop } from 'vue-property-decorator'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import CArticleDisplay from '~/components/dashboard/widget/collaborate/common/displays/ArticleDisplay.vue'
import CArticleTopicSummaryDisplay from '~/components/dashboard/widget/collaborate/common/displays/ArticleTopicSummaryDisplay.vue'
import CAssetsDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssetsDisplay.vue'
import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CPubDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/PubDateDisplay.vue'
import CTaskWithArticleTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskWithArticleTitleDisplay.vue'
import TopicListDataHandler from '~/components/dashboard/widget/collaborate/topicListWidget/content/DataHandler.vue'

@Component({
  components: {
    CArticleDisplay,
    CArticleTopicSummaryDisplay,
    CAssetsDisplay,
    CAssigneesDisplay,
    CBoardDisplay,
    CPubDateDisplay,
    CTaskWithArticleTitleDisplay,
    TopicListDataHandler,
  },
  name: 'dashboard-collaborate-topic-management-widget-content-data-listing',
})
export default class DataListing extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]

  public listHeaders: any = [
    {
      text: 'Board',
      sortable: false,
      value: 'startDatetime',
    },
    {
      text: 'Title',
      sortable: false,
      value: '',
    },
    {
      text: 'Assignees',
      sortable: false,
      value: 'assignees',
    },
    {
      text: 'Topic Summary',
      sortable: false,
      value: 'title',
    },
    {
      text: 'Topic Stories',
      sortable: false,
      value: '',
    },
    {
      text: 'Est / Act Pub Date Time',
      sortable: false,
      value: 'location',
    },
    {
      text: '',
      sortable: false,
      value: '',
    },
  ]

  public getCorrespondingTaskStyle(task: any) {
    const classes = []
    // Either rejected or cancelled
    if (task.status === TaskStatus.Cancelled) {
      classes.push('topic-list-data-listing__column-row--cancelled')
    } else if (task.status === TaskStatus.Rejected) {
      classes.push('topic-list-data-listing__column-row--rejected')
    }
    return classes
  }

  public mounted() {
    this.$emit('init')
  }
}

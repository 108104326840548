






import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import TopicListAddWidgetDialog from '~/components/dashboard/widget/collaborate/topicListWidget/addWidgetDialog/Dialog.vue'
import TopicListViewDetailsDialog from '~/components/dashboard/widget/collaborate/topicListWidget/viewDetailsDialog/Dialog.vue'

@Component({
  components: {
    TopicListAddWidgetDialog,
    TopicListViewDetailsDialog,
  },
  name: 'dashboard-collaborate-topic-list-widget-placeholder',
})
export default class Placeholder extends Vue {}











































































import { Component, Prop } from 'vue-property-decorator'
import { TaskArticleModel, TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import DataIterator from '~/components/common/DataIterator.vue'
import TextClamp from '~/components/common/TextClamp.vue'

import CArticleDisplay from '~/components/dashboard/widget/collaborate/common/displays/ArticleDisplay.vue'
import CArticleTopicSummaryDisplay from '~/components/dashboard/widget/collaborate/common/displays/ArticleTopicSummaryDisplay.vue'
import CAssetsDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssetsDisplay.vue'
import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CPubDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/PubDateDisplay.vue'
import CTaskWithArticleTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskWithArticleTitleDisplay.vue'
import TopicListDataHandler from '~/components/dashboard/widget/collaborate/topicListWidget/content/DataHandler.vue'

import eventhub from '~/components/dashboard/widget/collaborate/topicListWidget/eventhub'
import util from '~/util'
@Component({
  components: {
    CArticleDisplay,
    CArticleTopicSummaryDisplay,
    CAssetsDisplay,
    CAssigneesDisplay,
    CBoardDisplay,
    CPubDateDisplay,
    CTaskWithArticleTitleDisplay,
    TopicListDataHandler,
    DataIterator,
    TextClamp,
  },
  name: 'dashboard-collaborate-topic-management-widget-content-card-listing',
})
export default class CardListing extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]

  public getCorrespondingTaskStyle(task: TaskModel) {
    const classes = []
    // Either rejected or cancelled
    if (task.status === TaskStatus.Cancelled) {
      classes.push('topic-list-card-listing__column-row--cancelled')
    } else if (task.status === TaskStatus.Rejected) {
      classes.push('topic-list-card-listing__column-row--rejected')
    }
    return classes
  }

  public hasTopicSummary(task: TaskModel) {
    return !util.checkEmpty(task.articleSummary)
  }

  public handleLongPressClick(task: TaskModel, stories: TaskArticleModel[]) {
    return () => {
      eventhub.$emit('open-view-details-dialog', {
        config: this.config,
        task,
        stories,
      })
    }
  }

  public mounted() {
    this.$emit('init')
  }
}

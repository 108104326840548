var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{class:_vm.containerClasses},[_c('widget-name-display',{attrs:{"name":_vm.widget.name}}),_c('v-card',[(_vm.isMobile)?_c('v-layout',{attrs:{"wrap":""}},[_c('topic-list-header',{attrs:{"config":_vm.config}},[_c('refresh-button',{attrs:{"slot":"right-block","date":_vm.fetchDatetime,"loading":_vm.refreshLoading},on:{"click":_vm.handleRefreshBtnClick},slot:"right-block"})],1),_c('mobile-wrapper',[_c('v-flex',{attrs:{"xs12":""}},[_c('c-board-select',{attrs:{"group-id":_vm.config.groupId,"value":_vm.config.boardId},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('c-board-mobile-display',{attrs:{"items":props.data},on:{"change":_vm.handleBoardChange}})]}}],null,false,925483054)})],1),_c('v-flex',{attrs:{"xs12":"","pt-3":""}},[_c('custom-button-group',[_c('date-control',{attrs:{"value":_vm.widgetStartDate},on:{"change":_vm.handleStartDateChange,"close":_vm.handleStartDateClose},scopedSlots:_vm._u([{key:"display",fn:function(ref){
var on = ref.on;
return [_c('date-button-display',_vm._g({attrs:{"selected":_vm.startDateSelected,"date":_vm.widgetStartDate},on:{"click":_vm.handleStartDateClick}},on),[_c('today-button',{attrs:{"slot":"before-date","btn-classes":"ma-0 ml-1","no-margin":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleTodayClick.apply(null, arguments)}},slot:"before-date"})],1)]}}],null,false,1304606642)}),_c('date-control',{attrs:{"allowed-dates":_vm.endDateAllowedDates,"value":_vm.widgetEndDate},on:{"change":_vm.handleEndDateChange,"close":_vm.handleEndDateClose},scopedSlots:_vm._u([{key:"display",fn:function(ref){
var on = ref.on;
return [_c('date-button-display',_vm._g({attrs:{"selected":_vm.endDateSelected,"date":_vm.widgetEndDate},on:{"click":_vm.handleEndDateClick}},on),[_c('span',{staticClass:"ml-3",attrs:{"slot":"date-display"},slot:"date-display"},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.widgetEndDate,"DD MMM YYYY")))])])]}}],null,false,2486322116)})],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('preload',{attrs:{"display-loading":_vm.dataLoading}},[_c('topic-list-content',{attrs:{"data-ids":_vm.dataIds,"config":_vm.config},on:{"config-update":_vm.handleConfigUpdate}})],1)],1)],1):_c('v-layout',{attrs:{"wrap":""}},[_c('topic-list-header',{attrs:{"config":_vm.config}},[_c('v-divider',{attrs:{"slot":"left-block","vertical":"","inset":""},slot:"left-block"}),_c('c-board-select',{attrs:{"slot":"left-block","group-id":_vm.config.groupId,"value":_vm.config.boardId},on:{"change":_vm.handleBoardChange},slot:"left-block"}),_c('v-divider',{attrs:{"slot":"right-block","vertical":"","inset":""},slot:"right-block"}),_c('refresh-button',{attrs:{"slot":"right-block","date":_vm.fetchDatetime,"loading":_vm.refreshLoading},on:{"click":_vm.handleRefreshBtnClick},slot:"right-block"}),_c('v-divider',{attrs:{"slot":"right-block","vertical":"","inset":""},slot:"right-block"}),_c('v-spacer'),_c('preload',{attrs:{"slot":"right-block","display-loading":_vm.cancelLoading,"size":20},slot:"right-block"},[_c('close-button',{on:{"click":_vm.handleCloseBtnClick}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('preload',{attrs:{"display-loading":_vm.dataLoading}},[_c('topic-list-content',{attrs:{"task-ids":_vm.dataIds,"config":_vm.config},on:{"config-update":_vm.handleConfigUpdate}},[_c('custom-button-group',{staticClass:"mr-2",attrs:{"slot":"right-block"},slot:"right-block"},[_c('date-control',{attrs:{"value":_vm.widgetStartDate},on:{"change":_vm.handleStartDateChange,"close":_vm.handleStartDateClose},scopedSlots:_vm._u([{key:"display",fn:function(ref){
var on = ref.on;
return [_c('date-button-display',_vm._g({attrs:{"selected":_vm.startDateSelected,"date":_vm.widgetStartDate},on:{"click":_vm.handleStartDateClick}},on),[_c('today-button',{attrs:{"slot":"before-date","btn-classes":"ma-0 ml-1","no-margin":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleTodayClick.apply(null, arguments)}},slot:"before-date"})],1)]}}])}),_c('date-control',{attrs:{"allowed-dates":_vm.endDateAllowedDates,"value":_vm.widgetEndDate},on:{"change":_vm.handleEndDateChange,"close":_vm.handleEndDateClose},scopedSlots:_vm._u([{key:"display",fn:function(ref){
var on = ref.on;
return [_c('date-button-display',_vm._g({attrs:{"selected":_vm.endDateSelected,"date":_vm.widgetEndDate},on:{"click":_vm.handleEndDateClick}},on),[_c('span',{staticClass:"ml-3",attrs:{"slot":"date-display"},slot:"date-display"},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.widgetEndDate,"DD MMM YYYY")))])])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
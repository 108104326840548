





































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import {
  TaskArticlePublishStatusModel,
  TaskModel,
} from '@technology/collaborate-next-js/dist/types/models'
import { endOfDay, format, isAfter, isEqual, startOfDay } from 'date-fns'
import Vue from 'vue'

import CloseButton from '~/components/common/widget/buttons/CloseButton.vue'
import CustomButtonGroup from '~/components/common/CustomButtonGroup.vue'
import DateButtonDisplay from '~/components/common/widget/displays/DateButtonDisplay.vue'
import DateControl from '~/components/common/widget/controls/DateControl.vue'
import EnlargeWidgetButton from '~/components/common/widget/buttons/EnlargeWidgetButton.vue'
import MobileWrapper from '~/components/common/widget/wrappers/MobileWrapper.vue'
import Preload from '~/components/common/Preload.vue'
import RefreshButton from '~/components/common/widget/buttons/RefreshButton.vue'
import ShrinkWidgetButton from '~/components/common/widget/buttons/ShrinkWidgetButton.vue'
import TodayButton from '~/components/common/widget/buttons/TodayButton.vue'
import WidgetNameDisplay from '~/components/common/widget/displays/WidgetNameDisplay.vue'

import CBoardMobileDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardMobileDisplay.vue'
import CBoardSelect from '~/components/dashboard/widget/collaborate/common/inputs/BoardSelect.vue'

import TopicListContent from '~/components/dashboard/widget/collaborate/topicListWidget/content/Content.vue'
import TopicListHeader from '~/components/dashboard/widget/collaborate/topicListWidget/header/Header.vue'

import constant from '~/components/dashboard/widget/collaborate/common/constant'

import { WidgetConfigEntity } from '~/apollo/types'
import { dateFormat } from '~/filters'
import util from '~/util'
@Component({
  components: {
    CBoardSelect,
    CBoardMobileDisplay,
    CloseButton,
    TopicListContent,
    TopicListHeader,
    CustomButtonGroup,
    DateControl,
    DateButtonDisplay,
    EnlargeWidgetButton,
    MobileWrapper,
    Preload,
    RefreshButton,
    ShrinkWidgetButton,
    TodayButton,
    WidgetNameDisplay,
  },
  filters: {
    dateFormat,
  },
  name: 'dashboard-collaborate-topic-list-widget',
})
export default class TopicListWidget extends Vue {
  @Prop() public widget: WidgetConfigEntity
  @Prop() public widgetId: string
  @Prop() public config: KeyMap<any>

  public refreshInterval = 30000
  public enableCronjob = true

  public cancelLoading = false
  public dataLoading = false
  public refreshLoading = false
  public fetchDatetime = new Date()
  public dataIds: string[] = []
  public intervalId: NodeJS.Timeout

  public widgetStartDate: string = dateFormat(new Date(), 'YYYY-MM-DD')
  public widgetEndDate: string = dateFormat(new Date(), 'YYYY-MM-DD')
  public startDateSelected = false
  public endDateSelected = false

  get boards() {
    return this.$store.myGetters.getBoards
  }

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get containerClasses() {
    const classes = []
    // if (this.widget.size) {
    //   classes.push(`xs${this.widget.size}`)
    // } else {
    classes.push('xs12')
    // }
    return classes
  }

  get showEnlargeButton() {
    return this.widget.size && this.widget.size === '6'
  }

  get showShrinkButton() {
    return !this.widget.size || this.widget.size === '12'
  }

  public handleCloseBtnClick() {
    this.$alertDialog.showDialog({
      title: 'Warning',
      message: 'Are you sure to remove this widget?',
      buttons: [
        {
          text: 'Yes',
          color: 'primary',
          onClick: async () => {
            this.cancelLoading = true
            await this.$store.myActions.deleteWidgetConfig({
              widgetConfigId: this.widgetId,
            })
            this.cancelLoading = false
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })
  }

  public async handleConfigUpdate(config: KeyMap<any>) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        config: {
          ...config,
        },
      },
    })
  }

  public async handleBoardChange(boardId: string) {
    await this.handleConfigUpdate({
      ...this.config,
      boardId,
    })
    this.fetchData(true, this.getFetchBody(boardId))
  }

  public async handleResizeBtnClick(size: string) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        size,
      },
    })
  }

  public async handleRefreshBtnClick() {
    this.sendGAAction('event', 'TM', 'Click', 'Refresh')
    this.refreshLoading = true
    await this.fetchData(false)
    this.refreshLoading = false
  }

  // TODO: wait for vuetify date-range component
  // refs: https://github.com/vuetifyjs/vuetify/issues/1646
  // Date range support
  public handleTodayClick() {
    const today = dateFormat(new Date(), 'YYYY-MM-DD')
    this.widgetStartDate = today
    this.replaceEndDateIfAfter(today)
    this.startDateSelected = false
    this.fetchData()
  }

  public handleStartDateClose() {
    this.startDateSelected = false
  }

  public handleStartDateChange(date: string) {
    this.widgetStartDate = date
    this.replaceEndDateIfAfter(date)
    this.startDateSelected = false
    this.fetchData()
  }

  public handleStartDateClick() {
    this.startDateSelected = true
    this.endDateSelected = false
  }

  public handleEndDateClose() {
    this.endDateSelected = false
  }

  public handleEndDateChange(date: string) {
    this.widgetEndDate = date
    this.endDateSelected = false
    this.fetchData()
  }

  public handleEndDateClick() {
    this.startDateSelected = false
    this.endDateSelected = true
  }

  public endDateAllowedDates(date: string) {
    const dateWithoutTime = format(date, 'YYYY-MM-DD')
    return (
      isAfter(dateWithoutTime, this.widgetStartDate) ||
      isEqual(dateWithoutTime, this.widgetStartDate)
    )
  }

  public replaceEndDateIfAfter(targetDate: string) {
    if (isAfter(targetDate, this.widgetEndDate)) {
      this.widgetEndDate = targetDate
    }
  }
  // end

  @Watch('config')
  public onConfigChanged(to: KeyMap<any>, from: KeyMap<any>) {
    if (to.boardId !== from.boardId) {
      this.fetchData()
    }
  }

  public async fetchData(withLoading = true, body = this.getFetchBody()) {
    this.clearBackgroundJob()
    withLoading && (this.dataLoading = true)
    try {
      const result = await this.$store.myActions.fetchTopicManagement({
        ...body,
      })
      let tasks = result.data
      const pubStatuses = result.nested?.taskArticlePublishStatuses ?? []
      if (result.nested && result.nested.relatedTasks) {
        tasks = [...tasks, ...result.nested.relatedTasks]
      }
      await Promise.all([
        this.fetchGroupConfigs(this.config.groupId),
        this.fetchCollaborateAppConfig(),
        this.fetchRelatedUser(tasks, pubStatuses),
        this.fetchGroupUser(this.config.groupId),
      ])
      // Sort by event datetime
      this.dataIds = result.data
        .sort((a, b) => {
          const tA = this.$store.myGetters.getParentTaskById(a.id)
          const tB = this.$store.myGetters.getParentTaskById(b.id)
          const issueDateA = tA?.articleIssueDate ?? 0
          const issueDateB = tB?.articleIssueDate ?? 0
          return util.sortDate(issueDateA, issueDateB)
        })
        .map(task => task.id)
      this.fetchDatetime = new Date()
    } catch (e) {
      const err = e as any
      if (util.isObject(err.body)) {
        const { code, message } = err.body
        this.$store.myActions.SHOW_DIALOG({
          app: 'collaborate',
          type: 'error',
          code,
          description: message,
        })
      } else {
        console.log(err)
      }
    }
    withLoading && (this.dataLoading = false)
    this.enableCronjob && this.setNextJob()
  }

  public async fetchGroupConfigs(groupId: string) {
    if (util.checkEmpty(this.$store.myGetters.getGroupConfigByGroupId(groupId))) {
      return this.$store.myActions.fetchGroupConfigs()
    }
  }

  public async fetchCollaborateAppConfig() {
    if (!this.$store.myGetters.getCollaborateAppConfig) {
      return this.$store.myActions.fetchCollaborateAppConfig()
    }
  }

  public async fetchRelatedUser(tasks: TaskModel[], pubStatuses: TaskArticlePublishStatusModel[]) {
    const userIds = util
      .distinct([
        ...util.flatten(tasks.map(task => [...(task.assigneeIds || []), task.createdBy])),
        ...pubStatuses.map(p => p.userId),
      ])
      .filter(
        userId =>
          !util.checkEmpty(userId) && !this.$store.myGetters['accounts/user/getById'](userId),
      )
    if (userIds.length > 0) {
      return this.$store.myActions['accounts/user/fetchList']({
        q: userIds.join(','),
        type: 'ids',
      })
    }
  }

  public async fetchGroupUser(groupId: string) {
    return this.$store.myActions['accounts/groupUser/fetchList']({
      groupId,
      active: true,
    })
  }

  // Background job related
  public clearBackgroundJob() {
    this.intervalId && clearTimeout(this.intervalId)
  }

  public setNextJob() {
    this.intervalId = setTimeout(async () => {
      this.refreshLoading = true
      await this.fetchData(false)
      this.refreshLoading = false
    }, this.refreshInterval)
  }

  public async created() {
    this.sendGAAction('event', 'TM', 'Load')
    await this.fetchData()
  }

  public destroyed() {
    this.enableCronjob = false
    this.clearBackgroundJob()
  }

  private getFetchBody(boardId: string = '') {
    const baseBody: SearchWithNestedParams = {
      taskSearch: {
        groupIds: [this.config.groupId],
      },
    }
    const localBoardId = boardId || this.config.boardId
    if (localBoardId && localBoardId !== 'all') {
      baseBody.taskSearch.boardIds = [localBoardId]
    }

    if (this.config.dateType === constant.dateTypes.eventDate.value) {
      baseBody.taskSearch.eventDateStart = startOfDay(this.widgetStartDate).toISOString()
      baseBody.taskSearch.eventDateEnd = endOfDay(this.widgetEndDate).toISOString()
    } else {
      baseBody.taskSearch.issueDateStart = startOfDay(this.widgetStartDate).toISOString()
      baseBody.taskSearch.issueDateEnd = endOfDay(this.widgetEndDate).toISOString()
    }

    return baseBody
  }
}
